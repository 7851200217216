import '../styles/index.scss';
import $ from 'jquery'
import Isotope from "isotope-layout";
import tabs from './tabs';
import slider from './slider';
import videoFrameArray from './videoFrameArray'
import isotopList from './isotopList';
import mobMenu from './mobile-menu';
import scroll from './scroll';

window.addEventListener('DOMContentLoaded', () => {
    tabs('.tabs', '.switch__item', '.tabs__slide', 'tabs__active');
    tabs('.slider-tabs', '.icons-tabs__item', '.slider-animated__slide', 'icons-tabs__active');
    slider();
    scroll('.menu');
    videoFrameArray('.js-player-small');
})

window.addEventListener('load', () => {
    isotopList('.grid');
})

window.addEventListener('resize', () => {
    isotopList('.grid');
})


