import $ from 'jquery'
const tabs = (headerSelector, tabSelector, contentSelector, activeClass) => {

    if (document.querySelector(headerSelector)) {

        const header = document.querySelector(headerSelector);
        const tab = document.querySelectorAll(tabSelector);
        const content = document.querySelectorAll(contentSelector);

        function hideTabContent() {
            content.forEach(item => {
                $(item).fadeOut(0);
            });
            tab.forEach(item => {
                item.classList.remove(activeClass)
            });
        }
        function showTabContent(i = 0) {

            $(content[i]).fadeIn(0);
            tab[i].classList.add(activeClass)
        }

        hideTabContent();
        showTabContent();

        header.addEventListener('click', function (e) {
            const target = e.target
            if (target || target.classList.contains(tabSelector.replace(/\./, ""))) {
                tab.forEach((item, i) => {
                    if (target.closest(tabSelector) == item) {
                        hideTabContent();
                        showTabContent(i);
                    }
                });
            }
        })
    }
};

export default tabs;