const scroll = (selector) => {

    $(document).ready(function () {

        $(selector).on("click", "a", function (event) {
            event.preventDefault();
            $('.main-menu').removeClass('show');
            $('#burger').removeClass('open');
            var id = $(this).attr('href'),
                top = $(id).offset().top;
            $('body,html').animate({ scrollTop: top }, 1400);

        });

    });

}

export default scroll