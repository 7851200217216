const videoFrame = (selector) => {

    if (document.querySelector(selector)) {

        const playerSmall = Array.from(document.querySelectorAll(selector)).map(p => new Plyr(p, {

        }));

        playerSmall.forEach(function (item, i) {
            item.on('ready', event => {
                item.toggleControls(false)
                let videoWrapper = item.elements.container.childNodes[2];
                videoWrapper.addEventListener("click", event => {
                    item.paused();
                    event.stopPropagation();
                });
            });

        })

        const tabs = document.querySelector('.icons-tabs');
        tabs.addEventListener('click', function (e) {
            playerSmall.forEach(function (item, i) {
                item.stop()
            })
        })
    }
}
export default videoFrame;