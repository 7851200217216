import $ from "jquery";

const gWindow = $(window);

gWindow.on('scroll', function () {
    stickyHeader()

});

var navbar = document.getElementById("header");
var sticky = navbar.offsetTop;

function stickyHeader() {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}

$(document).ready(function () {
    $("#burger").click(function () {
        $(this).toggleClass("open");

        if ($(this).hasClass("open")) {
            $(".main-menu")
                .addClass("show")
                .css("transition", "transform 600ms cubic-bezier(.08,1.13,.46,.85)");
        } else {
            $(".main-menu").removeClass("show");
        }
    });

    function windowSize() {
        if ($(window).width() > "960") {
            $(".main-menu").css("transition", "none");
        }
    }
    gWindow.resize(windowSize);
});