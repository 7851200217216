import $ from 'jquery'
import {
    slick
} from "slick-carousel";
const slider = () => {

    $('#js-slider').slick({

        arrows: false,
        dots: false,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
        // rows: 0,
        responsive: [{
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                variableWidth: false,

            }
        },
        {
            breakpoint: 860,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },


        ]
    });

}

export default slider