const isotopList = function () {

    if (document.querySelector('.grid')) {

        // init Isotope
        let gridItem = $('.grid-item');
        let btnShowMore = $('#showMore');
        let reviewsSection = $('.reviews');
        let gutterWidth;
        let initial_items;
        let next_items;
        let $grid = $('.grid');


        function initIsotop() {
            $grid.isotope({
                itemSelector: '.grid-item',
                masonry: {
                    layoutMode: 'masonry',
                    gutter: gutterWidth,
                }
            });


        }

        if (window.innerWidth <= 768) {
            gutterWidth = 31
            initial_items = 2;
            next_items = 2;
            initIsotop()
            hideItems(initial_items);

        }
        else if (window.innerWidth > 768) {
            gutterWidth = 24
            initial_items = 6;
            next_items = 3;
            initIsotop()
            hideItems(initial_items);
        }
        else {
            return
        }






        function showNextItems(pagination) {
            var itemsMax = $('.visible_item').length;
            var itemsCount = 0;
            $('.visible_item').each(function () {
                if (itemsCount < pagination) {
                    $(this).removeClass('visible_item');
                    itemsCount++;
                }
                else {
                    return
                }
            });
            if (itemsCount >= itemsMax) {
                btnShowMore.hide();
                reviewsSection.removeClass('reviews-gradient');
            }
            $grid.isotope('layout');
        }
        // function that hides items when page is loaded
        function hideItems(pagination) {
            var itemsMax = gridItem.length;
            var itemsCount = 0;
            gridItem.each(function () {
                if (itemsCount >= pagination) {
                    $(this).addClass('visible_item');
                }
                else {
                    $(this).removeClass('visible_item');
                }
                itemsCount++;
            });
            if (itemsCount < itemsMax || initial_items >= itemsMax) {
                btnShowMore.hide();
                reviewsSection.removeClass('reviews-gradient');
            }
            $grid.isotope('layout');
        }
        btnShowMore.on('click', function (e) {
            e.preventDefault();
            showNextItems(next_items);
        });
    }
}

export default isotopList;